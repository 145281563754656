var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MyResume'),_c('section',{staticClass:"container-fluid g-px-70--lg g-px-50--sm g-pt-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-lg-6"},[_c('h5',{staticClass:"d-flex align-items-center g-color-gray-dark-v4 mb-0"},[_c('p',{staticClass:"g-font-size-20--sm g-font-size-36--lg"},[_c('router-link',{attrs:{"to":{ name: 'queries' }}},[_c('img',{attrs:{"src":"assets/img/arrow.svg"}})]),_vm._v(" Nº "+_vm._s(_vm.currentQuerySelected.query.folio)+" ")],1),_c('p',{staticClass:"g-font-size-20--sm g-font-size-26--lg g-color-darkgreen g-pl-50"},[(
                _vm.allConstants.dashboard.groups.claimant.queriesActive.statuses.includes(
                  _vm.currentQuerySelected.query.state
                )
              )?_c('span',{staticClass:"orange--text"},[(_vm.currentQuerySelected.query.state == 'PENDIENTE')?_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s("Ingresada"))]):_c('span',[_vm._v(_vm._s("Respondida"))])]):_vm._e(),(
                _vm.allConstants.dashboard.groups.claimant.queriesSolved.statuses.includes(
                  _vm.currentQuerySelected.query.state
                )
              )?_c('span',{staticClass:"orange--text"},[_vm._v(" "+_vm._s(_vm.currentQuerySelected.query.state == "RESUELTO" ? "Respondida" : "Ingresada")+" ")]):_vm._e()])])])]),_c('hr',{staticClass:"g-my-10",staticStyle:{"border":"1px solid"}})]),_c('Resolution'),_c('section',{staticClass:"container-fluid g-px-80--lg g-px-50--sm g-pt-40"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3 col-lg-3"},[_vm._m(0),_c('p',[_vm._v(" "+_vm._s(_vm.getBankName(_vm.currentQuerySelected.query.finnancialInstitute))+" ")])]),_c('div',{staticClass:"col-sm-3 col-lg-3"},[_vm._m(1),_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.getProductName(_vm.currentQuerySelected.query.productType))+" ")])])])]),_c('hr',{staticClass:"g-my-10",staticStyle:{"border":"1px solid"}})]),_c('section',{staticClass:"container-fluid g-px-80--lg g-px-50--sm g-pt-40 text-justify"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-12"},[_vm._m(2),_c('p',[_vm._v(_vm._s(_vm.currentQuerySelected.query.comments))])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:" g-pt-10"},[_c('img',{attrs:{"src":"assets/img/bank.svg"}}),_vm._v(" Banco o Institución ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:" g-pt-10"},[_c('img',{attrs:{"src":"assets/img/product.svg"}}),_vm._v(" Producto ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h6"},[_c('img',{attrs:{"src":"assets/img/msg-exc.svg"}}),_vm._v(" Consulta")])}]

export { render, staticRenderFns }