<template>
  <div>
    <MyResume />

    <section class="container-fluid g-px-70--lg g-px-50--sm g-pt-10">
      <div class="row">
        <div class="col-sm-6 col-lg-6">
          <h5 class="d-flex align-items-center g-color-gray-dark-v4 mb-0">
            <p class="g-font-size-20--sm g-font-size-36--lg">
              <router-link :to="{ name: 'queries' }">
                <img src="assets/img/arrow.svg" />
              </router-link>
              Nº {{ currentQuerySelected.query.folio }}
            </p>
            <p
              class="g-font-size-20--sm g-font-size-26--lg g-color-darkgreen g-pl-50"
            >
              <span
                class="orange--text"
                v-if="
                  allConstants.dashboard.groups.claimant.queriesActive.statuses.includes(
                    currentQuerySelected.query.state
                  )
                "
              >
                <span
                  class="orange--text"
                  v-if="currentQuerySelected.query.state == 'PENDIENTE'"
                  >{{ "Ingresada" }}</span
                >
                <span v-else>{{ "Respondida" }}</span>
              </span>
              <span
                class="orange--text"
                v-if="
                  allConstants.dashboard.groups.claimant.queriesSolved.statuses.includes(
                    currentQuerySelected.query.state
                  )
                "
              >
                {{
                  currentQuerySelected.query.state == "RESUELTO"
                    ? "Respondida"
                    : "Ingresada"
                }}
              </span>
            </p>
          </h5>
        </div>
      </div>
      <hr class="g-my-10" style="border: 1px solid;" />
    </section>

    <Resolution />

    <section class="container-fluid g-px-80--lg g-px-50--sm g-pt-40">
      <div class="row">
        <div class="col-sm-3 col-lg-3">
          <h6 class=" g-pt-10">
            <img src="assets/img/bank.svg" /> Banco o Institución
          </h6>
          <p>
            {{ getBankName(currentQuerySelected.query.finnancialInstitute) }}
          </p>
        </div>
        <div class="col-sm-3 col-lg-3">
          <h6 class=" g-pt-10">
            <img src="assets/img/product.svg" /> Producto
          </h6>
          <p>
            <span>
              {{ getProductName(currentQuerySelected.query.productType) }}
            </span>
          </p>
        </div>
      </div>
      <hr class="g-my-10" style="border: 1px solid;" />
    </section>

    <section
      class="container-fluid g-px-80--lg g-px-50--sm g-pt-40 text-justify"
    >
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="h6"><img src="assets/img/msg-exc.svg" /> Consulta</div>
          <p>{{ currentQuerySelected.query.comments }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

import MyResume from "@/components/PublicSite/User/MyResume";
import Resolution from "@/components/PublicSite/User/Query/Resolution";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  components: {
    MyResume,
    Resolution,
  },
  data() {
    return {
      showActions: false,
    };
  },
  methods: {
    async beforeMount() {
      window.scrollTo(0, 0);
      if (
        this.currentQuerySelected.currentEvent == null ||
        this.currentQuerySelected.currentEvent == undefined ||
        this.currentQuerySelected.currentEvent.stageCode == undefined ||
        this.currentQuerySelected.currentEvent.stageCode == null
      )
        return;
    },
  },
};
</script>

<style lang="scss" scoped></style>
