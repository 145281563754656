<template>
  <section
    class="container-fluid g-px-60--lg g-px-50--sm g-pt-10"
    v-if="
      currentQuerySelected.query.reply != undefined ||
        currentQuerySelected.query.reply != null ||
        currentQuerySelected.query.reply != ''
    "
  >
    <div class="media-body u-shadow-v22 g-bg-light-green g-pa-30 g-rounded-15">
      <v-row>
        <v-col cols="6">
          <h6 class="">
            <img src="assets/img/name.svg" /> Ejecutivo Defensoría del Cliente
          </h6>
          <p v-if="currentQuerySelected.lastHumanEvent != null">
            {{ currentQuerySelected.lastHumanEvent.userName }}
          </p>
        </v-col>
        <v-col cols="6">
          <p class="h6"><img src="assets/img/calendar.svg" />Fecha Ingreso</p>
          <p>
            {{ currentQuerySelected.query.lastUpdateDate | formatDateShort }}
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p class="h6">Respuesta</p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '01'"
          >
            Estimado(a) Señor(a):<br /><br />
            Junto con saludar, invitamos a revisar el Reglamento de la
            Defensoría del cliente en el siguiente enlace:
            <a href="https://www.defensoriadelclientedeabif.cl/estatutos"
              >https://www.defensoriadelclientedeabif.cl/estatutos</a
            >, a través del cual podrá conocer si su caso es de competencia de
            esta Defensoría.<br />
            En caso, de que usted estime que sí lo es, deberá formalizar el
            reclamo a través de nuestra página
            <a href="https://www.defensoriadelclientedeabif.cl/">
              https://www.defensoriadelclientedeabif.cl/</a
            >
            o bien acercándose a la sucursal más cercana de su banco, en la cual
            podrá solicitar el Formulario de Reclamo de la Defensoría del
            Cliente en el mesón de atención al cliente.<br /><br />Atentamente
            Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '02'"
          >
            Estimado(a) Señor(a):<br /><br />
            Junto con saludar, en el siguiente enlace
            <a
              href="https://www.defensoriadelclientedeabif.cl/assets/doc/DDC-Manual-ingreso-de-reclamos-Usuarios-DDC.pdf"
            >
              https://www.defensoriadelclientedeabif.cl/assets/doc/DDC-Manual-ingreso-de-reclamos-Usuarios-DDC.pdf</a
            >, podrá encontrar toda la información sobre como ingresar un
            reclamo con nosotros.<br />
            <br />
            Atentamente Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '03'"
          >
            Estimado(a) Señor(a):<br />
            <br />
            Dado que su inquietud, es de carácter comercial, y que depende de
            cada banco, y de la relación que ha tenido con el cliente. Le
            sugerimos se acerque a un ejecutivo de la respectiva institución
            para que aclare sus dudas.<br />
            <br />
            Atentamente Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '04'"
          >
            Estimado(a) Señor(a):<br /><br />
            Dado el carácter específico de su consulta o reclamo, el cual es el
            resultado de una relación con el Banco. Su requerimiento no puede
            ser conocido por esta Defensoría, ya que excede sus competencias.<br /><br />
            Para mayores antecedentes, le invitamos a revisar el Reglamento de
            la Defensoría del Cliente Bancario en el siguiente enlace:
            <a href="https://www.defensoriadelclientedeabif.cl/estatutos"
              >https://www.defensoriadelclientedeabif.cl/estatutos</a
            >.<br />
            <br />
            Finalmente, le sugerimos acercarse a un ejecutivo de la respectiva
            institución para aclarar sus dudas.<br /><br />
            Atentamente Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '05'"
          >
            Estimado(a) Señor(a):<br /><br />
            Junto con saludar, luego de haber analizado su solicitud debemos
            informarle que de acuerdo con lo establecido en el Reglamento de la
            Defensoría del Cliente Bancario, su reclamo excede las competencias
            de ésta, dado que está siendo tramitado en sede judicial, y es ahí
            donde se puede solicitar lo que usted plantea.
            <br /><br />
            Atentamente Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '06'"
          >
            Estimado(a) Señor(a):<br /><br />
            Junto con saludarlo, de acuerdo con el reglamento de esta Defensoría
            del Cliente Bancario, su caso se encuentra finalizado, por lo tanto,
            no podemos realizar mas gestiones al respecto.<br /><br />
            Le hacemos presente que el hecho que usted no este conforme con lo
            resuelto por el defensor del cliente, no le hace perder ningún
            derecho para que pueda plantear su caso a las instancias judiciales
            o administrativas pertinentes. <br /><br />
            Atentamente Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '07'"
          >
            Estimado(a) Señor(a):<br /><br />
            Junto con saludarlo, en respuesta a su consulta efectuada a través
            de la Defensoría del Cliente Bancario, informamos que, daremos aviso
            de lo indicado por usted a las autoridades correspondiente del
            Banco. <br /><br />
            Agradecemos la información entregada. <br /><br />
            Atentamente Defensoría del Cliente.
          </p>
          <p
            style="text-align: justify;"
            v-if="currentQuerySelected.query.typeResponseCode == '08'"
          >
            Estimado(a) Señor(a):<br /><br />
            Dado que su consulta, supone una opinión profesional, excede nuestra
            competencia. Le sugerimos asesoría profesional. <br /><br />
            Atentamente Defensoría del Cliente.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>{{ currentQuerySelected.query.reply }}</p>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentQuerySelectedMixin from "@/mixins/currentQuerySelected";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentQuerySelectedMixin,
  ],
  methods: {},
};
</script>

<style lang="scss" scoped></style>
