import { render, staticRenderFns } from "./QueryDetail.vue?vue&type=template&id=6ce96528&scoped=true&"
import script from "./QueryDetail.vue?vue&type=script&lang=js&"
export * from "./QueryDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce96528",
  null
  
)

export default component.exports